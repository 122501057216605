/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~@ng-select/ng-select/themes/default.theme.css";

.custom-loader {
    --background: transparent;
    ion-backdrop {
      background-color: #fff;
      opacity: 0.9 !important;
    }
    .loading-wrapper {
      -webkit-animation: ld-vortex-out 2s ease-out infinite;
      animation: ld-vortex-out 2s ease-out infinite;
      animation-timing-function: cubic-bezier(0.05, 0, 3, 0.05);
  
      background-image:  url("assets/image/loader2.gif");
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      min-width: 150px;
      min-height: 150px;
      box-shadow: none;
      -webkit-box-shadow: none;
    }
  }

//   ion-item {
//     --border-color: var(--ion-color-any, #002c4f);
//   }